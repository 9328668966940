(function ($) {
  if (typeof window.$ === 'undefined' || !window.$) {
    throw "This theme need jQuery";
  }


  /**
   //////////////////////////////// * Doc ready //////////////////////////////
   */

  var shuffleme = (function () {
    'use strict';
    var vGrid = $('.grid'), //locate what we want to sort
      filterOptions = $('.showcase-sorting li'),  //locate the filter categories
      vSizer = vGrid.find('.shuffle_sizer'),    //sizer stores the size of the items


      // Re layout shuffle when images load. This is only needed
      // below 768 pixels because the .picture-item height is auto and therefore
      // the height of the picture-item is dependent on the image
      // I recommend using imagesloaded to determine when an image is loaded
      // but that doesn't support IE7
      listen = function () {
        var debouncedLayout = $.throttle(300, function () {
          vGrid.shuffle('update');
        });

        // Get all images inside shuffle
        vGrid.find('img').each(function () {
          var proxyImage;

          // Image not yet loaded
          if (!this.complete || typeof this.naturalWidth === 'undefined') {
            // Simulate loading on detached element.
            proxyImage = new Image();
            $(proxyImage).on('load', function () {
              $(this).off('load');
              debouncedLayout();
            });

            proxyImage.src = this.src;
          }
        });

        // Because this method doesn't seem to be perfect.
        setTimeout(function () {
          debouncedLayout();
        }, 500);
      },

      // Set up button clicks
      setupFilters = function () {
        var vBtns = filterOptions.children();
        vBtns.on('click', function (e) {
          e.preventDefault();
          var vThis = $(this),
            isActive = vThis.hasClass('active'),
            group = isActive ? 'all' : vThis.data('group');

          // Hide current label, show current label in title
          if (!isActive) {
            filterOptions.find('a').removeClass('active');
          }

          vThis.toggleClass('active');

          // Filter elements
          vGrid.shuffle('shuffle', group);
        });

        // vBtns = null;
      },

      init = function () {

        // None of these need to be executed synchronously
        setTimeout(function () {
          listen();
          setupFilters();
        }, 100);

        // instantiate the plugin
        vGrid.shuffle({
          itemSelector: '[class*="col-"]',
          speed: 350,
          sizer: vSizer
        });
      };

    return {
      init: init
    };
  }());

  $(function () {
    $('.hides').hide();

    // $('.expand-link').on('click', function(){
    // 	$(this).find('i').toggleClass('fa-angle-down');
    // 	$(this).find('i').toggleClass('fa-angle-up');
    // 	$('.hides').toggle();
    // 	$('.see').toggle();
    // })

    $('.nav .search a').on('click', function (e) {
      e.preventDefault();
      $(this).parent('.search').toggleClass('opened');
    });

    shuffleme.init();
  });

  var scroll = 0;
  $(window).scroll(function () {
      var scroll = $(this).scrollTop();
      if (scroll > 30) {
        $('header').addClass('scrolled');
      } else {
        $('header').removeClass('scrolled');
      }
  });


})(jQuery);
